<template>
  <div style="height: 100vh">
    <div class="card card-custom gutter-b" style="height: 100vh">
      <div class="card-header border-0 py-5">
        <h3 class="card-title font-weight-bolder text-dark">
          Report by days in range:
          <span class="ml-5"
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >{{ range.start | formatDate }}</span
            ></span
          >
          <span
            ><span class="flex-shrink-0 my-2 mx-3"
              ><i class="la la-arrow-right"></i></span
          ></span>
          <span
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >{{ range.end | formatDate }}</span
            ></span
          >
          <span class="ml-3"
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >{{ days.length }} days</span
            ></span
          >
        </h3>
        <div class="card-toolbar">
          <button
            v-if="
              this.currentUserCompanyModule[0] === 'gaming' &&
              type === 'location'
            "
            @click.prevent="exportAsExcel"
            class="btn btn-light-primary font-weight-bold px-10"
          >
            Export .xlsx
          </button>
        </div>
      </div>
      <div class="card-body mx-0 px-0">
        <apexchart
          v-if="isReady"
          :options="options"
          :series="series"
          height="100%"
          width="100%"
          style="height: 100%; width: 100%"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { dateFilter } from 'vue-date-fns'
import format from 'date-fns/format'
import eachDayOfInterval from 'date-fns/eachDayOfInterval'
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'DaysTraffic',
  filters: {
    date: dateFilter,
  },
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    type: {
      type: String,
    },
    traffic: {
      type: Array,
    },
    selected: {
      type: Array,
    },
    range: {
      type: Object,
    },
  },
  data() {
    return {
      transactions: [],
      coinTransactions: [],
      smsTransactions: [],
      cardTransactions: [],
      loyaltyTransactions: [],
      tokenTransactions: [],
      days: [],
      options: {
        tooltip: {
          enabled: true,
          x: {
            show: true,
            formatter(val) {
              return val
            },
          },
          y: {
            formatter(val) {
              return (
                val
                  .toFixed(2)
                  .replace('.', ',')
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
              )
            },
            title: {
              formatter(seriesName) {
                return seriesName
              },
            },
          },
        },
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              orientation: 'horizontal',
              position: 'center',
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: 0,
          offsetY: 0,
          formatter(val) {
            if (val !== null) {
              return val
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
            }
            return val
          },
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
          },
        },
        yaxis: {
          labels: {
            show: true,
            formatter(val) {
              return val
            },
          },
        },
        legend: {
          show: false,
        },
      },
      series: [
        {
          name: 'PayCoin',
          data: [],
        },
        {
          name: 'PaySMS',
          data: [],
        },
        {
          name: 'PayCard',
          data: [],
        },
        {
          name: 'PayLoyalty',
          data: [],
        },
      ],
      isReady: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyModule', 'currentUserCompanyOib']),
  },
  mounted() {
    const mq = window.matchMedia('(min-width: 480px)')

    if (!mq.matches) {
      this.options.chart.type = 'area'
      this.options.xaxis.labels.show = false
      this.options.yaxis.show = false
    }

    if (this.currentUserCompanyModule[0] === 'gaming')
      this.options.legend.show = true

    this.days = eachDayOfInterval({
      start: new Date(this.range.start),
      end: new Date(this.range.end),
    })

    if (this.days.length >= 30) {
      this.options.plotOptions.bar.horizontal = true
      this.options.plotOptions.bar.dataLabels.orientation = 'horizontal'
      this.options.plotOptions.bar.dataLabels.position = 'bottom'
      this.options.dataLabels.offsetX = 5
      this.options.dataLabels.offsetY = 0

      this.days.forEach((day) => {
        let dayCoinAmount = 0
        let daySmsAmount = 0
        let dayCardAmount = 0
        let dayLoyaltyAmount = 0

        this.traffic.forEach((transaction) => {
          if (
            transaction.createdAt.slice(0, 10) === format(day, 'dd.MM.yyyy')
          ) {
            if (
              transaction.paymentType === 'pay_coin' ||
              transaction.paymentType === 'pay_cctalk' ||
              transaction.paymentType === 'pay_mdb' ||
              transaction.paymentType === 'pay_sci'
            )
              dayCoinAmount += parseFloat(transaction.amount)
            if (transaction.paymentType === 'pay_sms')
              daySmsAmount += parseFloat(transaction.amount)
            if (transaction.paymentType === 'pay_card')
              dayCardAmount += parseFloat(transaction.amount)
            if (transaction.paymentType === 'pay_rfcard')
              dayLoyaltyAmount += parseFloat(transaction.amount)
          }
        })

        this.series[0].data.push(dayCoinAmount)
        this.series[1].data.push(daySmsAmount)
        this.series[2].data.push(dayCardAmount)
        this.series[3].data.push(dayLoyaltyAmount)
        this.options.xaxis.categories.push(format(day, 'dd.MM.yyyy'))
      })
    } else if (this.days.length > 14 && this.days.length < 29) {
      this.options.plotOptions.bar.horizontal = false
      this.options.plotOptions.bar.dataLabels.orientation = 'vertical'
      this.options.plotOptions.bar.dataLabels.position = 'center'
      this.options.dataLabels.offsetX = 0
      this.options.dataLabels.offsetY = 0
      this.days.forEach((day) => {
        let dayCoinAmount = 0
        let daySmsAmount = 0
        let dayCardAmount = 0
        let dayLoyaltyAmount = 0

        this.traffic.forEach((transaction) => {
          if (
            transaction.createdAt.slice(0, 10) === format(day, 'dd.MM.yyyy')
          ) {
            if (
              transaction.paymentType === 'pay_coin' ||
              transaction.paymentType === 'pay_cctalk' ||
              transaction.paymentType === 'pay_mdb' ||
              transaction.paymentType === 'pay_sci'
            )
              dayCoinAmount += parseFloat(transaction.amount)
            if (transaction.paymentType === 'pay_sms')
              daySmsAmount += parseFloat(transaction.amount)
            if (transaction.paymentType === 'pay_card')
              dayCardAmount += parseFloat(transaction.amount)
            if (transaction.paymentType === 'pay_rfcard')
              dayLoyaltyAmount += parseFloat(transaction.amount)
          }
        })

        this.series[0].data.push(dayCoinAmount)
        this.series[1].data.push(daySmsAmount)
        this.series[2].data.push(dayCardAmount)
        this.series[3].data.push(dayLoyaltyAmount)
        this.options.xaxis.categories.push(format(day, 'dd.MM.yyyy'))
      })
    } else {
      this.days.forEach((day) => {
        let dayCoinAmount = 0
        let daySmsAmount = 0
        let dayCardAmount = 0
        let dayLoyaltyAmount = 0

        this.traffic.forEach((transaction) => {
          if (
            transaction.createdAt.slice(0, 10) === format(day, 'dd.MM.yyyy')
          ) {
            if (
              transaction.paymentType === 'pay_coin' ||
              transaction.paymentType === 'pay_cctalk' ||
              transaction.paymentType === 'pay_mdb' ||
              transaction.paymentType === 'pay_sci'
            )
              dayCoinAmount += parseFloat(transaction.amount)
            if (transaction.paymentType === 'pay_sms')
              daySmsAmount += parseFloat(transaction.amount)
            if (transaction.paymentType === 'pay_card')
              dayCardAmount += parseFloat(transaction.amount)
            if (transaction.paymentType === 'pay_rfcard')
              dayLoyaltyAmount += parseFloat(transaction.amount)
          }
        })

        this.series[0].data.push(dayCoinAmount)
        this.series[1].data.push(daySmsAmount)
        this.series[2].data.push(dayCardAmount)
        this.series[3].data.push(dayLoyaltyAmount)
        this.options.xaxis.categories.push(format(day, 'dd.MM.yyyy'))
      })
    }

    setTimeout(() => {
      this.isReady = true
    }, 500)
  },
  methods: {
    exportAsExcel() {
      const from = format(this.range.start, "yyyy-MM-dd'T'HH:mm:ss")
      const end = format(this.range.end, "yyyy-MM-dd'T'HH:mm:ss")

      let temLocationsIds = ''
      this.selected.forEach((location) => {
        let l = location['@id'].replace('/api/v1/locations/', '')
        temLocationsIds += l + ', '
      })

      axios
        .get(
          `${process.env.VUE_APP_ROUTE_API_URL}/api/v1/exports/xlsx/locations-revenue-by-days/${this.currentUserCompanyOib}/${temLocationsIds}/${from}/${end}`
        )
        .then(({ data }) => {
          if (data.status === 'ok') {
            window.location.replace(data.fileUrl)
            this.$notify({
              group: 'notification',
              type: 'success',
              title: 'Export success',
            })
          }
        })
    },
  },
}
</script>
